import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { LoginSchema } from "app/shared/schemas/login.schema";
import { ErrorList, Field } from "components/Interface/Forms/forms.tsx";
import ProviderAuthWrapper from "components/Layout/Provider/ProviderAuthWrapper.tsx";
import { Headline } from "components/UI/Headline";
import { Label, LabelVariants } from "components/UI/Label";
import { Paragraph } from "components/UI/Paragraph";
import { ClintButton } from "components/shadcn-ui/button.tsx";
import {
  type ActionFunctionArgs,
  Form,
  Link,
  type LoaderFunctionArgs,
  type MetaFunction,
  data,
  redirect,
  useActionData,
  useNavigation,
  useSearchParams,
} from "react-router";
import { generateCanonicalUrl } from "routes/redirects.server";
import {
  authenticateUser,
  checkIfUserExists,
  getUserSession,
} from "server/auth.server";
import { generateMetatags } from "utils/generateMetatags";

const dynamicLinks = ({
  data,
}: {
  data: { href: string };
}): ReturnType<MetaFunction> => [
  {
    rel: "canonical",
    href: data.href,
  },
];

export const handle = {
  // 👇This is a function that will be called client side with a 'href' argument.
  dynamicLinks,
};

export const meta: MetaFunction = ({ location }) => {
  return generateMetatags({
    noIndex: true,
    pageUrl: location.pathname,
  });
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const userToken = await getUserSession({ request });
  if (userToken) return redirect("/auth");
  return data({ href: generateCanonicalUrl({ request }) });
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const submission = await parseWithZod(formData, {
    schema: LoginSchema.superRefine(async ({ email, password }, ctx) => {
      const { userExists } = await checkIfUserExists({
        withPassword: true,
        email,
        password,
      });

      if (!userExists) {
        ctx.addIssue({
          code: "custom",
          path: ["email"],
          message: "Ce compte n'existe pas.",
        });
        return false;
      }
    }),
    async: true,
  });

  if (submission.status !== "success") {
    return data(
      { result: submission.reply(), status: "idle" },
      {
        status: 400,
      },
    );
  }
  return await authenticateUser({
    data: submission.value,
    request,
    customerAuth: false,
  });
};

const LoginPage = () => {
  const actionData = useActionData<typeof action>();
  const transition = useNavigation();
  const isLoading = transition.state !== "idle";
  const [searchParams] = useSearchParams();

  const paramsEmail = searchParams.get("email") ?? "";
  const [form, fields] = useForm({
    defaultValue: {
      redirectTo: searchParams.get("redirectTo"),
      email: paramsEmail,
    },
    id: "login-form",
    constraint: getZodConstraint(LoginSchema),
    onValidate({ formData }) {
      return parseWithZod(formData, {
        schema: LoginSchema,
      });
    },
    shouldRevalidate: "onSubmit",
    lastResult: actionData?.result,
  });

  return (
    <ProviderAuthWrapper>
      <div className="flex flex-col gap-4 w-full">
        <Headline size="h1" variant="secondary">
          Se connecter
        </Headline>
        <Label size="L" className="text-gray-600">
          Goodcollect, la manière la plus simple de faire collecter et recycler
          ses déchets
        </Label>
      </div>

      <div className="mt-12 w-full">
        <Form
          {...getFormProps(form)}
          method="POST"
          action="/login"
          className="flex flex-col space-y-4 lg:space-y-6"
        >
          <Field
            inputProps={{
              ...getInputProps(fields.email, {
                type: "email",
              }),
              autoComplete: "email",
            }}
            labelProps={{
              children: "Email",
            }}
            errors={fields.email.errors}
          />

          <Field
            inputProps={{
              ...getInputProps(fields.password, {
                type: "password",
              }),
              type: "password",
            }}
            labelProps={{
              children: "Mot de passe",
            }}
            errors={fields.password.errors}
          />

          <input {...getInputProps(fields.redirectTo, { type: "hidden" })} />
          <input
            {...getInputProps(fields.action, { type: "hidden" })}
            value="login"
          />
          <ErrorList errors={form.errors} id={form.errorId} />

          <Link
            className={LabelVariants({
              size: "M",
              className: "text-gray-800 underline underline-offset-2",
            })}
            to={`/forgot-password${paramsEmail ? `?email=${paramsEmail}` : ""}`}
          >
            Mot de passe oublié
          </Link>
          <ClintButton
            as="Button"
            variant="primary"
            isLoading={isLoading}
            disabled={isLoading}
            type="submit"
          >
            Se connecter
          </ClintButton>
          <div className="flex items-center gap-2">
            <Paragraph size="md" className="text-gray-600">
              Vous n'avez pas de compte ?
            </Paragraph>

            <Link
              className={LabelVariants({
                size: "M",
                className: "text-gray-800 underline underline-offset-2",
              })}
              to={`/register-provider`}
            >
              Créer un compte
            </Link>
          </div>
        </Form>
      </div>
    </ProviderAuthWrapper>
  );
};

export default LoginPage;
